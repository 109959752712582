.image {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray6_100);
  background-size: cover;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background: rgba(0, 0, 0, 0.6);
  }
}

.close {
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  padding: 0;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--gray60_100);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.info {
  flex-grow: 1;
}

.logo {
  align-items: center;
  margin-right: 16px;
  display: flex;
  width: 60px;

  & > img {
    width: 60px;
    height: auto;
  }
}

.copyright {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4px;
  width: 100%;
}
