@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.title {
  margin-bottom: 6px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  text-decoration: none;

  &:hover * {
    color: var(--accent-main-primary);
  }
}

.title,
.house {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  -webkit-line-clamp: 2; /* stylelint-disable-line value-no-vendor-prefix */
  line-clamp: 2;
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
}

.house {
  margin-top: 4px;
  color: var(--gray60_100);
}

.subtitle {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: var(--decorative-theme-dark);
}
