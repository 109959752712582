@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.input {
  padding: 3px 36px 3px 7px;
  height: auto;
  font-size: 14px;
  line-height: 20px;
  color: var(--decorative-theme-dark);
  border: 1px solid var(--gray20_100);
  border-radius: 4px;
  outline: none;
}

.input:hover,
.input:focus {
  border-color: var(--decorative-theme-dark);
}

.input::placeholder {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray40_100);
}

.button {
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: #999;
  background: none;
  border: none;
  transform: translateY(-50%);
}

.button:hover {
  color: #7a7a7a;
}
