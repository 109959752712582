.info {
  display: flex;

  &__group {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 8px;
    }

    & > span {
      margin-right: 4px;
      display: inline-flex;

      & > svg {
        color: inherit;
      }
    }
  }
}

.type {
  color: var(--icon-secondary-default);
}
