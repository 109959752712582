@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  cursor: pointer;
  pointer-events: auto;
  color: var(--accent-main-primary);
  background: var(--decorative-theme-white);
  border: none;
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
