.dropdown {
  position: absolute;
  z-index: 100;
  top: 52px;
  right: 12px;
  width: 370px;
  pointer-events: auto;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}
