@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

a.navigation-btn {
  color: var(--decorative-theme-dark);
}

a.listing-btn {
  margin-left: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--decorative-theme-dark);
}

a.listing-btn--mobile {
  margin-left: 0;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0 var(--unit_2) var(--unit_4) 0 rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
}

.listing-title {
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.listing-title--mobile {
  font-size: 16px;
  line-height: 24px;
}

.loader {
  width: 156px;
  height: 16px;
  text-align: center;
}

.title {
  margin: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
