.middle-navigation-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 88px;
}

.large-navigation-btn {
  position: relative;
  z-index: 101;
  display: flex;
}
