.layer {
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.layer button,
.layer a,
.layer input {
  pointer-events: auto;
}
