@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  position: absolute;
  pointer-events: none;
}

.container {
  overflow: hidden;
  width: 248px;
  background-color: var(--decorative-theme-white);
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.indent--small {
  padding: 3.5px;
}

.indent--medium {
  padding: 4px;
}

.indent--large {
  padding: 6px;
}

.indent--extra-large {
  &.placement--left,
  &.placement--right {
    padding: 52px;
  }

  &.placement--top,
  &.placement--top-right,
  &.placement--top-left {
    padding: 32px;
  }

  &.placement--bottom,
  &.placement--bottom-right,
  &.placement--bottom-left {
    padding: 6px;
  }
}

.placement--right {
  transform: translate(16px, -50%);
}

.placement--left {
  transform: translate(calc(-100% - 16px), -50%);
}

.placement--top {
  transform: translate(-50%, calc(-100% - 16px));
  /* 42px */
}

.placement--bottom {
  transform: translate(-50%, 16px);
}

.placement--top-right {
  transform: translate(16px, calc(-100% - 16px));
}

.placement--top-left {
  transform: translate(-100%, calc(-100% - 16px));
}

.placement--bottom-right {
  transform: translate(16px, 16px);
}

.placement--bottom-left {
  transform: translate(calc(-100% - 16px), 16px);
}

.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.image-block {
  position: relative;
  width: 100%;
  height: 144px;
  background-color: var(--gray6_100);
}

.label {
  position: absolute;
  left: 12px;
  bottom: 6px;
  padding: 1px 8px 3px;
  font-size: 12px;
  line-height: 18px;
  color: var(--decorative-theme-white);
  background-color: var(--accent-main-primary);
  border-radius: 2px;
}

.loading .label {
  display: none;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading .image {
  display: none;
}

.content-block {
  padding: 12px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: var(--decorative-theme-dark);
}

.loading .title {
  margin-bottom: 8px;
  width: 149px;
  height: 16px;
  font-size: 0;
  background-color: var(--gray6_100);
  border-radius: 2px;
}

.features {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: var(--decorative-theme-dark);
}

.loading .features {
  width: 100%;
  height: 16px;
  background-color: var(--gray6_100);
  border-radius: 2px;
}

.features-item {
  position: relative;
}

.features-item + .features-item {
  padding-left: 16px;
}

.features-item + .features-item::before {
  position: absolute;
  top: calc(50% - 2px);
  left: 6px;
  width: 4px;
  height: 4px;
  content: '';
  background-color: var(--gray60_100);
  border-radius: 50%;
}

.loading .features-item {
  display: none;
}
