/* stylelint-disable-next-line */
:global(#map-search-frontend) {
  display: flex;
  flex: 1 1 auto;
}

.container {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100vw;
}

.map-container {
  position: relative;
  z-index: 0;
  display: flex;
  flex: 1;
  min-height: 300px;
}
