/* stylelint-disable cian/no-custom-colors */
.select-popup {
  z-index: 1;
  overflow-y: auto;
  max-height: 400px;
  background: white;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
