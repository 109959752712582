@import '../../../../constants/css/variables.css';

.container {
  position: absolute;
  z-index: 10;
  top: 12px;
  width: 100%;
  pointer-events: none;
  transition: padding-left 0.3s ease-in-out;
}

.wrapper {
  margin: 0 auto;
  padding: 0 16px;
  min-width: 704px;
  max-width: 1376px;
}

.content {
  transition: padding-left 0.3s ease-in-out;
}

@media (max-width: 1376px) {
  .content {
    padding-right: 108px;
  }

  .content--ectopic {
    padding-left: var(--map-sidebar-width);
  }
}

@media (min-width: 1377px) and (max-width: 1560px) {
  .content {
    padding-right: calc(108px - ((100vw - 1344px) / 2));
  }
}

@media (min-width: 1377px) and (max-width: 2000px) {
  .content--ectopic {
    padding-left: calc((var(--map-sidebar-width) - ((100vw - 1344px) / 2)) + 16px);
  }
}
