@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  padding: 18px 24px;
  text-align: center;
  pointer-events: none;
  color: white;
  background: var(--overlay-popover);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .container {
    padding: 16px 20px;
  }
}
