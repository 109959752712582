.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  pointer-events: none;
  color: white;
  border: 2px solid white;
  border-radius: 22px;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.3));
  transform: translate(-50%, -50%);
}

/* @todo выпилить эксп CD-159979 */
.dailyrent-container {
  display: none;
}

.container--small {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}

.container--blue {
  background-color: var(--accent-main-primary);
  box-shadow: 0 0 0 5px rgba(4, 104, 255, 0.4);
}

.container--green {
  background-color: var(--accent-positive-primary);
  box-shadow: 0 0 0 5px rgba(151, 218, 124, 0.4);
}
