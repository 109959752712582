.container {
  position: relative;
  padding-left: 16px;
  overflow: hidden;
  width: 100%;
}

.text {
  font-size: 10px;
  line-height: 14px;
  color: var(--decorative-theme-white);
}

.ticker {
  position: relative;
  padding-right: 12px;
  padding-left: 0;
  display: inline-block;
  box-sizing: content-box;
  white-space: nowrap;
  animation-name: ticker;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.ticker--static {
  animation-name: none;
}

.ticker--infinite {
  padding-left: 100%;
  animation-name: tickerInifnite;
  animation-iteration-count: infinite;
  animation-fill-mode: initial;
}

@keyframes ticker {
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes tickerInifnite {
  0% {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
