.wrapper {
  display: flex;
  text-decoration: none;
}

.image {
  position: relative;
  margin-right: 16px;
  width: 64px;
  height: 64px;
}

.newbuilding-image {
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.developer-logo {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  padding: 2px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 3px 0;
}

.title,
.address {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  -webkit-line-clamp: 2;
}

.address {
  -webkit-line-clamp: 1;
}
