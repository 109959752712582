.iframe {
  width: 100%;
  height: 100%;
}

.iframe.loading {
  opacity: 0;
}

.container {
  width: 100%;
  height: 100%;
  flex: auto;
}

.loader-wrapper {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
