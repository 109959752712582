.container {
  position: absolute;
  z-index: 1;
  left: 0;
  padding: 8px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  pointer-events: none;
}

.middle-indent {
  padding: 8px;
  justify-content: space-between;
}

.large-indent {
  padding: 12px;
  justify-content: flex-end;
}

.large-indent--redesign {
  padding: 12px;
  justify-content: space-between;
}
