@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.small-pin {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 12px;
  height: 12px;
  background-color: var(--decorative-theme-dark);
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.large-pin {
  position: absolute;
  top: -16px;
  left: -16px;
  width: 32px;
  height: 32px;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.icon {
  margin: 7px auto;
  display: block;
  width: 16px;
  height: 16px;
  color: currentcolor;
}

.small-pin > .icon {
  display: none;
}
