.container {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  pointer-events: none;
}

.middle-indent {
  right: 8px;
}

.middle-indent--redesign {
  right: 12px;
}

.large-indent {
  right: 12px;
}

.map-controls {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media (min-width: 1025px) {
  .container {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

.top-group > * {
  margin: 8px 0;
}

.geo {
  margin-top: 16px;
}

.layer {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 58px;
  pointer-events: none;
}

.layer_geo {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 116px;
  pointer-events: none;
}

.zoom {
  margin-bottom: 8px;
}

.top-group--redesign {
  position: absolute;
  bottom: 60px;

  & > * {
    margin-bottom: 12px;
  }
}

@media (max-width: 320px) {
  .top-group--redesign {
    bottom: 28px;
  }
}
