.container {
  position: relative;
  padding: 4px;
  width: 40px;
  height: 40px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-origin: content-box;
  background-size: contain;
  border-radius: 4px;
  filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.1));
  transform: translate(-50%, -100%);
  transform: translate(-50%, calc(-100% - 4px));
}

.container--pik {
  background-color: white;
  background-image: url('https://cdn.cian.site/media/images/pik_orange.svg');
}

.container--active {
  padding: 8px;
  width: 48px;
  height: 48px;
}

.like {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 12px;
  height: 12px;
  background-image: url('./assets/like.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
