@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  margin-top: 4px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  background: var(--decorative-theme-white);
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.buttons {
  display: block;
}

.type {
  margin: 0 8px 8px 0;
  padding: 9px 16px;
  display: inline-flex;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
  background: white;
  border: 1px solid #ced1d7;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.type_active {
  background: var(--gray6_100);
  border: 1px solid var(--decorative-theme-dark);
}

.reset {
  margin-top: 12px;
}

.reset-btn {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: var(--accent-main-primary);
  background: transparent;
  border: none;
}

.reset-btn:disabled {
  cursor: default;
  color: var(--gray40_100);
}

.icon {
  margin-right: 8px;
  display: flex;
}

.text {
  font-size: 16px;
  line-height: 22px;
}
