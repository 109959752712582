.container {
  display: block;
}

.icon {
  margin-right: 8px;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
}
