.close {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.content {
  display: flex;
}

.img {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #ffddc9 0%, #c1e9fd 100%);
  border-radius: 6px;

  @media (min-width: 1025px) {
    width: 60px;
    height: 60px;
  }
}

.img img {
  width: 27px;
  height: 30px;
}

.link {
  margin-top: 4px;
  display: inline-block;
  text-decoration: none;
  color: var(--accent-main-primary);
}
