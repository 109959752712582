@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.input {
  position: relative;
  width: 100%;
}

.postfix {
  color: var(--decorative-theme-dark);
}
