@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  display: inline-flex;
  transform: translate(-50%, -32px);
}

/* @todo выпилить эксп CD-159979 */
.posutochno_wrapper {
  display: inline-flex;
  transform: translate(-50%, -24px);
}

.balloon {
  padding: 2px;
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  white-space: nowrap;
  color: var(--decorative-theme-dark);
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.price {
  margin-right: 2px;
  margin-left: 2px;
}

.icon {
  margin-right: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
}

.count {
  margin-right: 2px;
  padding: 0 5px;
  color: #737a8e;
  background-color: #f1f2f4;
  border-radius: 2px;
}

.favorite {
  margin-right: 2px;
  margin-left: 2px;
}

.viewed .balloon {
  color: var(--text-secondary-disabled);
}

.viewed .price {
  color: var(--text-secondary-disabled);
}

.viewed .balloon:not(.active) .count {
  color: var(--text-secondary-disabled);
  background: var(--surface-neutral-default);
}

.active {
  color: white;
  background-color: var(--accent-main-primary);
}

.residential-complex.colorized {
  .count {
    color: white;
    background-color: var(--accent-positive-primary);
  }
}

.viewed .colorized.residential-complex {
  .count {
    color: var(--text-secondary-disabled);
    background: var(--surface-neutral-default);
  }
}

.active.residential-complex {
  background-color: var(--accent-positive-primary);

  &.colorized .count {
    color: var(--text-primary-default);
    background-color: white;
  }

  &:not(.colorized) .count {
    color: var(--text-secondary-default);
    background-color: white;
  }
}

.active .price {
  color: white;
}

/* @todo выпилить эксп CD-159979 */
.posutochno_balloon {
  transform: translate(0, 8px);
}
