.select {
  position: relative;
  display: inline-block;
  outline: none;
}

.native-select {
  display: none;

  &.mobile {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    opacity: 0;
  }
}

.select-popup {
  position: absolute;
  min-width: 100%;

  &.upward {
    bottom: calc(100% + 8px);
  }

  &:not(.upward) {
    top: calc(100% + 8px);
  }
}
