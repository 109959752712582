.mobile-banner {
  position: relative;
  z-index: 11;
  left: 0;
  bottom: 0;
  padding: 8px 24px 8px 16px;
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--gray10_100);
  box-shadow: none;
}
