@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: relative;
}

.dropdown {
  position: absolute;
  z-index: 2;
  margin-top: 8px;
  padding: 12px;
  box-sizing: border-box;
  width: 300px;
  background: var(--decorative-theme-white);
  border: 1px solid var(--gray10_100);
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}
