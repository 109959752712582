.icon {
  position: relative;
}

.icon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  content: '';
  background: var(--accent-negative-primary);
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: expand 0.3s ease-in-out;
}

@keyframes expand {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
