.container {
  padding: 10px 16px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  flex: none;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
