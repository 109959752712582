@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  margin-top: 4px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: var(--accent-main-primary);
  border-top: 1px solid var(--gray10_100);
}

.container:hover {
  color: var(--control-main-primary-hovered);
}
