@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: relative;
  padding: 4px 30px 4px 12px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  pointer-events: auto;
  color: var(--decorative-theme-white);
  background: var(--overlay-popover);
  border-radius: 4px;
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  padding-right: 6px;
  width: 24px;
  height: 100%;
  cursor: pointer;
  color: inherit;
  background: none;
  border: none;
}
