.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.toggle {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
