@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--gray20_100);
}

.spinner svg path {
  opacity: 0.2;
  transform: translate3d(0, 0, 0);
  transform-origin: center;
  animation-name: circle-spin;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}

.spinner svg path:nth-of-type(1) {
  animation-delay: 0.1s;
}

.spinner svg path:nth-of-type(2) {
  animation-delay: 0.2s;
}

.spinner svg path:nth-of-type(3) {
  animation-delay: 0.3s;
}

.spinner svg path:nth-of-type(4) {
  animation-delay: 0.4s;
}

.spinner svg path:nth-of-type(5) {
  animation-delay: 0.5s;
}

.spinner svg path:nth-of-type(6) {
  animation-delay: 0.6s;
}

.spinner svg path:nth-of-type(7) {
  animation-delay: 0.7s;
}

.spinner svg path:nth-of-type(8) {
  animation-delay: 0.8s;
}

.spinner svg path:nth-of-type(9) {
  animation-delay: 0.9s;
}

.spinner svg path:nth-of-type(10) {
  animation-delay: 1s;
}

.spinner svg path:nth-of-type(11) {
  animation-delay: 1.1s;
}

.spinner svg path:nth-of-type(12) {
  animation-delay: 1.2s;
}

@keyframes circle-spin {
  from {
    opacity: 1;
    transform: scale(1.2);
  }
  to {
    opacity: 0.2;
    transform: scale(1);
  }
}
