@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';
@import '../../constants/css/variables.css';

.container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: var(--map-sidebar-width);
  height: 100%;
  background: white;
  border-right: 1px solid var(--gray10_100);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.opened {
  transform: translateX(0);
}
