@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  position: relative;
}

.image-block {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 180px;
  background-color: var(--gray6_100);
}

.content-block {
  padding: 16px;
}

.footer {
  margin-top: 18px;
}

.loading .title {
  margin-bottom: 8px;
  width: 100%;
  height: 48px;
  font-size: 0;
  background-color: var(--gray6_100);
  border-radius: 2px;
}

.loading .footer {
  width: 138px;
  height: 28px;
  background-color: var(--gray6_100);
  border-radius: 2px;
}

.loading .undergrounds {
  margin-top: 12px;
  width: 100%;
  height: 40px;
  background-color: var(--gray6_100);
  border-radius: 2px;
}

.is-visible-contacts {
  .image-block {
    height: 136px;
  }

  .footer {
    margin-top: 0;
  }

  .entry {
    display: none;
  }
}
