@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  background-color: white;
  border-bottom: 1px solid var(--gray10_100);
}

.secondary-group {
  width: 100%;
}

.geo {
  width: 100%;
}

.saved-search {
  margin-left: 8px;
  white-space: nowrap;
}

.layout {
  margin: 0 auto;
  padding: 16px;
  display: flex;
  min-width: 704px;
  max-width: 1376px;
}

@media (max-width: 1279px) {
  .layout {
    flex-direction: column-reverse;
  }

  .saved-search {
    margin-left: 6px;
  }
}
