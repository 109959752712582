@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.underground {
  display: flex;
  flex-wrap: wrap;
  white-space: inherit;
}

.underground-group {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.underground-icon {
  flex: none;
}

.underground-icon,
.underground-name {
  display: inline-block;
  vertical-align: middle;
}

.address {
  margin-top: 12px;
  color: var(--gray60_100);
}

.underground-name {
  margin-right: 6px;
  overflow: hidden;
  max-width: 244px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--gray60_100);
}

.underground-icon {
  flex: none;
  position: relative;
  top: 1px;
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.underground-icon > svg {
  display: inline-block;
  color: inherit;
}

.distance-group {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.distance-icon {
  flex: none;
  margin-right: 4px;
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: contain;
}

.distance-time {
  vertical-align: middle;
  color: var(--gray60_100);
}

.distance-icon--walk {
  background-image: url('../assets/distance_walk.svg');
}

.distance-icon--transport {
  background-image: url('../assets/distance_transport.svg');
}

.underground-icon--www {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_mow.svg');
  mask-image: url('../assets/metro_mow.svg');
}

.underground-icon--ekb {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_ekb.svg');
  mask-image: url('../assets/metro_ekb.svg');
}

.underground-icon--kazan {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_kazan.svg');
  mask-image: url('../assets/metro_kazan.svg');
}

.underground-icon--nn {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_nn.svg');
  mask-image: url('../assets/metro_nn.svg');
}

.underground-icon--novosibirsk {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_novosib.svg');
  mask-image: url('../assets/metro_novosib.svg');
}

.underground-icon--samara {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_samara.svg');
  mask-image: url('../assets/metro_samara.svg');
}

.underground-icon--spb {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-mask-image: url('../assets/metro_spb.svg');
  mask-image: url('../assets/metro_spb.svg');
}
