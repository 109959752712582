@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
  color: var(--decorative-theme-dark);
  background: white;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

.container > * {
  display: block;
}

.container.rounded {
  border-radius: 50%;
}

.container.middle {
  min-width: 40px;
  height: 40px;
}

.container.large {
  min-width: 48px;
  height: 48px;
}

.container.extra-large {
  min-width: 54px;
  height: 54px;
}

.container.active {
  color: var(--decorative-theme-white);
  background: var(--accent-main-primary);
  border-color: var(--accent-main-primary);
}

@media (min-width: 1025px) {
  .container:hover {
    background: var(--gray10_100);
    border-color: var(--gray10_100);
  }

  .container:focus {
    border-color: var(--stroke-control-focused);
  }

  .container:active {
    background: #d0d3d9;
    border-color: #d0d3d9;
  }

  .container.active:hover {
    background: var(--control-main-primary-hovered);
    border-color: var(--control-main-primary-hovered);
  }

  .container.active:active {
    background: var(--control-main-primary-pressed);
    border-color: var(--control-main-primary-pressed);
  }
}
