.container {
  display: flex;
  align-items: flex-start;
}

.content {
  margin-left: 8px;
  max-width: 276px;
}

.link {
  margin-top: 4px;
  text-decoration: none;
}

.mobile {
  position: relative;
  z-index: 11;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid var(--gray10_100);
  box-shadow: none;
}
