@import '../../constants/css/variables.css';

.container {
  position: absolute;
  left: 22px;
  bottom: 48px;
  padding: 8px 28px 8px 8px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease-in-out;
  will-change: transform;
}

.container--moved {
  transform: translateX(calc(var(--map-sidebar-width) - 6px));
}

.close {
  position: absolute;
  top: 6px;
  right: 6px;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
