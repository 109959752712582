/* stylelint-disable cian/no-custom-colors */
.option {
  position: relative;
  padding: 0 32px 0 12px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 32px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  outline: none;

  &.focused {
    background: rgba(43, 135, 219, 0.1);
  }
  &.multiple {
    padding-left: 38px;
  }
}

.icon,
.checkbox {
  position: absolute;
  cursor: pointer;
}
.icon {
  right: 12px;
}
.checkbox {
  left: 12px;
}

.marker {
  margin-left: 8px;
  padding: 2px 8px;
  font-size: var(--fontSize_12px);
  font-weight: bold;
  line-height: var(--lineHeight_16px);
  color: var(--decorative-theme-white);
  background: var(--accent-negative-primary);
  border-radius: 2px;
}
