.btn svg {
  display: block;
}

.btn {
  padding: 12px;
  display: flex;
  text-decoration: none;
  color: var(--decorative-theme-dark);
  align-items: center;
}

.title {
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}
