.layer {
  right: 0;
  bottom: 0;
}

.icon.large svg {
  width: 20px;
  height: 20px;
}

.icon.extra-large svg {
  width: 24px;
  height: 24px;
}
