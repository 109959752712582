@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.tags {
  position: absolute;
  left: 16px;
  bottom: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  overflow: hidden;
}

.tag {
  padding: 0 8px;
  overflow: hidden;
  max-width: 200px;
  height: 24px;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  color: white;
  background-color: var(--gray60_100);
  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.tag-rate {
  display: flex;
  align-items: center;
}

.tag--orange {
  background-color: var(--accent-warning-primary);
}

.tag--red {
  background-color: var(--accent-negative-primary);
}

.star {
  margin-right: 4px;
  margin-left: -4px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  background-image: url('../assets/star.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.tag-text {
  vertical-align: middle;
}

.tag-text {
  font-size: 12px;
  line-height: 12px;
}
