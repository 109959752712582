/* stylelint-disable cian/no-custom-colors */
@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  position: relative;
  padding: 0 8px;
  display: inline-flex;
  max-width: 260px;
  height: 28px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: #121212;
  background-color: white;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  outline: none;
  transition:
    backgroud-color var(--transition-option),
    border-color var(--transition-option),
    box-shadow 0.05s;
  backface-visibility: hidden;
  justify-content: space-between;
  align-items: center;

  &:hover {
    border-color: #7a7a7a;
  }

  &:active,
  &.active {
    background-color: #e9f3fb;
  }

  &:disabled {
    cursor: not-allowed;
    color: #c9c9c9;
    background-color: #e4e4e4;
    border-color: #c9c9c9;
  }
}

.button-underlined {
  position: relative;
  padding: 0;
  display: inline-flex;
  max-width: 260px;
  height: 28px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: var(--accent-main-primary);
  background: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  transition:
    backgroud-color var(--transition-option),
    border-color var(--transition-option),
    box-shadow 0.05s;
  backface-visibility: hidden;
  justify-content: space-between;
  align-items: center;
}

.button.desktop {
  padding: 0 8px;
  height: 28px;
}

.button.mobile {
  padding: 0 12px;
  height: 40px;
  font-size: 16px;
}

.button:focus,
:focus .button {
  &:not(.active) {
    box-shadow: 0 0 0 3px #95c3ed;
  }
}

.content {
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button-underlined .content {
  margin-top: 2px;
  border-bottom: 1px dashed var(--accent-main-primary);
}

.icon {
  margin-left: 9px;
  display: flex;
  flex: 0 0 auto;
}

.active .icon {
  transform: rotate(180deg);
}

.invalid {
  border-color: #ff5050;
}

.invalid:focus,
.invalid:hover {
  border-color: #ff5050;
}

@media (max-width: 640px) {
  .button:not(.desktop) {
    padding: 0 12px;
    height: 40px;
    font-size: 16px;
  }
}
