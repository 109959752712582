@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.wrapper {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
}

.container {
  overflow: hidden;
  width: 330px;
  pointer-events: all;
  background-color: var(--decorative-theme-white);
  border-radius: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.indent--extra-large {
  &.placement--left {
    padding-right: 52px;
  }

  &.placement--right {
    padding-left: 52px;
  }

  &.placement--top {
    padding-bottom: 28px;
  }
  &.placement--top-right,
  &.placement--top-left {
    padding: 32px;
  }

  &.placement--bottom {
    padding-top: 0;
  }

  &.placement--bottom-right,
  &.placement--bottom-left {
    padding: 6px;
  }
}

.placement--right {
  transform: translate(16px, -50%);
}

.placement--left {
  transform: translate(calc(-100% - 16px), -50%);
}

.placement--top {
  transform: translate(-50%, calc(-100% - 16px));
  /* 42px */
}

.placement--bottom {
  transform: translate(-50%, 16px);
}

.placement--top-right {
  transform: translate(16px, calc(-100% - 16px));
}

.placement--top-left {
  transform: translate(-100%, calc(-100% - 16px));
}

.placement--bottom-right {
  transform: translate(16px, 16px);
}

.placement--bottom-left {
  transform: translate(calc(-100% - 16px), 16px);
}
